html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Exo 2", sans-serif !important;
  font-optical-sizing: auto;
  /* font-weight: ; */
  font-style: normal;
}

#root {
  height: 100%;
}